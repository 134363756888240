<template lang="">
    <v-card>
        
        <s-crud
            :config="config"
            title="Tarimas"
            :filter="filter"
            add
            edit
            remove
            @save="saveScaffold($event)"
        >
            <template v-slot:filter>
                <v-container>
                    <v-row>                        
                        <v-col>
                            <s-text
                                label="Codigo"
                                v-model="filter.ScfCode"
                            ></s-text>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot="props">
                <v-container>
                    <v-row >                                        
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-text
                                label="Codigo*" 
                                v-model="props.item.ScfCode"/>
                        </v-col> 
                         <v-col class="s-col-form" sm="3" md="3">
                            <s-text                                
                                label="Descripcion*" 
                                v-model="props.item.ScfDescription"/>
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-select-definition                                
                                :def="1391"
                                label="Estado"
                                v-model="props.item.ScfStatus"
                                />
                        </v-col>
                    </v-row>
                     
                </v-container>
            </template>
        </s-crud>
    </v-card>
</template>

<script>

    import SText from '../../../components/Utils/SText.vue'
    import ServiceScaffold from '@/services/FreshProduction/ConfigFresh/ScaffoldService'

    export default {
        components: {
        SText
        },
        data() {
            return {
                filter:{
                    ScfCode: ""
                },
                config: {
                    service: ServiceScaffold,
                    model: {
                        ScfId : "ID"
                    },
                    headers: [
                        { text: "ID", value: "ScfId" },
                        { text: "Codigo", value: "ScfCode" },
                        { text: "Descripcion", value: "ScfDescription" },
                        { text: "Estado", value: "ScfStatusName" },
                    ]  
                }  
            }
        },
        methods:{
            saveScaffold(item){
                
            console.log(item);
            if(item.ScfCode.length <= 3){
                this.$fun.alert("El campo Codigo es Obligatorio","warning");
                return;     
            }

                item.save();
            }        
        }
    }

</script>